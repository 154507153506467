import React from 'react';
import PropTypes from 'prop-types';
import Link from 'atomic-design/atoms/link';
import {
  Container,
  Description,
  Image,
  ImageContainer,
  Line,
  LineContainer,
  TextContainer,
  Title,
  Content,
  LinkStyled,
} from './styles';

const ManifestoItem = ({ image, alt, title, description, isLast, link }) => {
  return (
    <Container key={title}>
      <ImageContainer>
        <Image src={image} alt={alt} />
      </ImageContainer>
      <Content>
        <LineContainer>{!isLast && <Line />}</LineContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description>
            {description}
            {link && <LinkStyled to={link.url}>{link.text}</LinkStyled>}
          </Description>
        </TextContainer>
      </Content>
    </Container>
  );
};

ManifestoItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
};

ManifestoItem.defaultProps = {
  isLast: false,
};

export default ManifestoItem;
