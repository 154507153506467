import styled from 'styled-components';

export const List = styled.ul`
  width: 80%;
  padding: 0;
  margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(15)} 0`};

  h5 {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    color: ${({ color }) => color};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    }
  }

  p {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(10)} 0`};
    width: 100%;
  }
`;

export const Subtitle = styled.h5`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  margin: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  margin-top: ${({ theme }) => theme.newTheme.spacing(8)};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(8)};
  color: ${({ theme }) => theme.newTheme.color.black};

  span {
    display: table;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    background: ${({ theme }) => theme.newTheme.color.gradient.text};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      display: block;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  }
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

  span {
    display: block;
    color: ${({ theme }) => theme.newTheme.color.grey.main};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};

    span {
      display: inline;
      margin-right: ${({ theme }) => theme.newTheme.spacing(1)};
    }
  }
`;

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(2)};
`;
