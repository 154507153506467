import React from 'react';

import Link from 'atomic-design/atoms/link';
import Button from 'atomic-design/atoms/button';
import Manifesto from 'atomic-design/molecules/manifesto';
import { List, Subtitle, Title, Text, Container } from './styles';

const ListCircleImages = ({ subtitle, title, description, list, colorList, buttonText }) => {
  return (
    <>
      <Subtitle>{subtitle}</Subtitle>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <List color={colorList}>
        {list.map(manifestoItem => (
          <Manifesto key={manifestoItem.title} {...manifestoItem} />
        ))}
      </List>

      <Container>
        <Link to="/contact" rel="noreferrer">
          <Button variant="contained" ariaLabel="get started">
            {buttonText}
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default ListCircleImages;
