import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

export const Container = styled.li`
  display: flex;
  position: relative;
  width: 100%;
`;

export const ImageContainer = styled.span`
  height: ${({ theme }) => theme.spacing.spacing90};
  max-height: ${({ theme }) => theme.spacing.spacing90};
  max-width: ${({ theme }) => theme.spacing.spacing90};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.buttonPrimary};
  overflow: hidden;
  position: absolute;
  z-index: 9;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    height: ${({ theme }) => theme.spacing.spacing90};
    max-height: ${({ theme }) => theme.spacing.spacing90};
    max-width: ${({ theme }) => theme.spacing.spacing90};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: ${({ theme }) => theme.spacing.spacing40};
    max-height: ${({ theme }) => theme.spacing.spacing40};
    max-width: ${({ theme }) => theme.spacing.spacing40};
  }
`;

export const Image = styled.img`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  z-index: 0;
`;

export const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: ${({ theme }) => theme.spacing.spacing90};
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    max-width: ${({ theme }) => theme.spacing.spacing90};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    max-width: ${({ theme }) => theme.spacing.spacing40};
  }
`;

export const Line = styled.div`
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

export const TextContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.large};
  margin-left: ${({ theme }) => theme.spacing.large};
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.largest};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) =>
      `padding: ${theme.spacing.smallest} ${theme.spacing.medium} ${theme.spacing.medium} 0`};
    margin-left: ${({ theme }) => theme.spacing.smallXL};
  }
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading5};
  margin: 0;
  font-weight: ${({ theme }) => theme.fonts.weights.boldCondensed};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fonts.sizes.primary};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.fonts.colors.secondary};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  padding-top: ${({ theme }) => theme.spacing.medium};
  margin: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fonts.sizes.secondary};
    padding: ${({ theme }) => theme.spacing.small} 0;
  }
`;

export const LinkStyled = styled(Link)`
  border-bottom: 2px solid ${({ theme }) => theme.newTheme.color.primary.main};
`;
